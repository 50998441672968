import styled from "styled-components";
export const Container = styled.div`
  height: 97%;
  width: 90.2vw;
  margin-inline-end: 2.2vw;
  border-radius: 1.6vh;
  display: flex;
  align-content: flex-start;
  column-gap: 3%;
  row-gap: 2vh;
`;
export const ContainerCategories = styled.div`
  background: #f8f3e5;
  height: 77vh;
  margin-top: -5vh;
  padding: 0.9vw;
  width: 10.5vw;
  border-radius: 1.6vh;
  display: flex;
  align-content: flex-start;
  column-gap: 3%;
  row-gap: 2vh;
  flex-wrap: wrap;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const ContainerItems = styled.div`
  height: 100%;
  width: 88vw;
  padding-inline-start: 2.6vw;
  border-radius: 1.6vh;
  display: flex;
  align-content: flex-start;
  column-gap: 3%;
  row-gap: 2vh;
  flex-wrap: wrap;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const Category = styled.div`
  height: 12vh;
  width: 100%;
  font-size: 0.8vw;
  font-weight: 800;
  background: ${({ active }) => (active ? "var(--orange)" : "white")};
  border-radius: 1vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.2vh;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  &:hover {
    background: ${({ active }) => (active ? "var(--orange)" : "var(--cream)")};
  }
`;
export const ImgCategory = styled.img`
  padding-inline: 2vw;
  width: 100%;
`;

export const Item = styled.div`
  font-size: 0.8vw;
  width: 23.6vw;
  position: relative;
  padding-inline-start: 3.4vw;
`;

export const Card = styled.img`
  height: 100%;
  width: 100%;
`;
export const DetailItem = styled.div`
  position: absolute;
  inset-inline-start: 8.9vw;
  top: 2.1vw;
  display: flex;
  column-gap: 1.4vw;
  align-items: flex-start;
  flex-direction: column;
`;
export const CoverImg = styled.div`
  position: absolute;
  inset-inline-start: 0.2vw;
  top: 0.7vw;
  height: 6.6vw;
  width: 6.6vw;
  background: white;
  border-radius: 1vw;
  div {
    position: relative;
    height: 100%;
    width: 100%;
    div {
      position: absolute;
      top: 0.4vw;
      right: 0.4vw;
      width: 2vw;
      height: 2vw;
      border-radius: 50%;
      background: ${({ status }) =>
        status === "active"
          ? "rgba(71, 134, 32, 0.20)"
          : "rgba(154, 40, 39, 0.2)"};

      div {
        top: 0.5vw;
        right: 0.5vw;
        width: 1vw;
        height: 1vw;
        background: ${({ status }) =>
          status === "active" ? "var(--green)" : "var(--red)"};
      }
    }
  }
`;

export const Img = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 1vw;
`;
export const Title = styled.p`
  width: 14.8vw;
  font-size: 1vw;
  font-weight: 700;
  padding-inline-end: 1.3vw;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const Description = styled.p`
  width: 14.8vw;
  font-size: 0.76vw;
  font-weight: 600;
  padding-inline-end: 1.3vw;
  padding-top: 0.8vw;
  color: var(--gray);
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const Price = styled.p`
  font-size: 1vw;
  font-weight: ${({ isPrice }) => (isPrice ? "900" : "400")};
  padding-top: 1.2vw;
  color: var(--orange);
`;
export const Unit = styled.span`
  font-size: 0.8vw;
  font-weight: 700;
  padding-inline-start: 0.4vw;
  color: black;
`;
export const Btn = styled.button`
  position: absolute;
  inset-inline-end: 0.7vw;
  bottom: 1.2vw;
  height: 2.7vw;
  width: 2.7vw;
  border-radius: 1vw;
  border: none;
  margin-right: auto;
  background: var(--orange);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: var(--lightBrown);
  }
`;
export const Icon = styled.img`
  height: 100%;
  width: 100%;
`;
export const BtnAdd = styled.button`
  position: absolute;
  inset-inline-start: 1vw;
  bottom: 1.8vh;
  height: 2.8vw;
  border-radius: 1vw;
  border: none;
  background: var(--brown);
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-inline-end: 1.3vw;
  font-weight: 600;
  font-size: 0.9vw;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: black;
  }
`;
export const IconBtnAdd = styled.img`
  height: 100%;
`;
