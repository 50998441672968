import {
  Container,
  Item,
  Img,
  CoverImg,
  Title,
  Description,
  Div,
  Time,
  Detail,
  DivFilters,
  Btnfilter,
  DivInputSearch,
  SearchIcon,
  StatusDiv,
  DivItems,
} from "./ElementTradeUnionsRequests";
import defaultLogo from "../../Images/headerIcons/tradeUnions.svg";
import searchIcon from "../../Images/search.svg";
import agreeIcon from "../../Images/agreeIcon.svg";
import denyIcon from "../../Images/denyIcon.svg";
import invalidIcon from "../../Images/invalidIcon.svg";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputSearch } from "../DeliveryCompanies/Modals/Element";
const List = ({ data, filter, filterStatus, handleSelectItem, filters }) => {
  const [items, setItems] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [activeCompany, setActiveCompany] = useState(null);

  const { t } = useTranslation();
  useEffect(() => {
    if (data) {
      setItems(data);
    }
  }, [data]);
  const handleSelectTab = (status) => {
    filterStatus(status);
    setActiveTab(status);
  };
  const handleSelectCompany = (item) => {
    handleSelectItem(item);
    setActiveCompany(item.id);
  };
  return (
    <>
      <Container>
        <DivFilters>
          <Btnfilter
            onClick={() => handleSelectTab(0)}
            active={activeTab === 0}
          >
            همه
          </Btnfilter>
          {filters.map((item) => (
            <Btnfilter
              onClick={() => handleSelectTab(item?.id)}
              active={item?.id === activeTab}
            >
              {item?.title}
            </Btnfilter>
          ))}
        </DivFilters>
        <DivInputSearch nonMarginTop>
          <SearchIcon src={searchIcon} />
          <InputSearch
            placeholder="جستجو کنید ..."
            id="search"
            onChange={filter}
          />
        </DivInputSearch>
        <DivItems>
          {items.map((item) => (
            <Item
              active={item.id === activeCompany}
              onClick={() => handleSelectCompany(item)}
            >
              <CoverImg>
                <Img
                  isLogo={item?.branch?.trade_unit?.logo}
                  src={
                    item?.branch?.trade_unit?.logo
                      ? item?.branch?.trade_unit?.logo
                      : defaultLogo
                  }
                />
              </CoverImg>
              <Detail>
                <Div>
                  <Title active={item.id === activeCompany}>
                    {item?.branch?.trade_unit?.title +
                      " _ " +
                      item?.branch?.title}
                  </Title>
                  <Time
                    status={
                      // item?.status === 0 &&
                      (item.id !== activeCompany && "PENDING") ||
                      (item?.status === 0 &&
                        item.id === activeCompany &&
                        "ACTIVE_PENDING") ||
                      (item?.status === 1 && "AGREE") ||
                      (item?.status === 2 && "DENY") ||
                      (item?.status === 3 && "INVALID")
                    }
                  >
                    {item?.jalali_payment_date}
                  </Time>
                </Div>
                <Description active={item.id === activeCompany}>
                  {"شماره فاکتور : " + item?.number}
                </Description>
              </Detail>
            </Item>
          ))}
        </DivItems>
      </Container>
    </>
  );
};

export default List;
