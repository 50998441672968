import {
  Container,
  Item,
  Card,
  Btn,
  Icon,
  DetailItem,
  Img,
  CoverImg,
  Title,
  Description,
  Price,
  Unit,
  BtnAdd,
  IconBtnAdd,
  ContainerItems,
  ContainerCategories,
  Category,
  ImgCategory,
} from "./ElementList";
import card from "../../Images/cards/product.svg";
import card_ltr from "../../Images/cards/product_ltr.svg";
import setting from "../../Images/setting.svg";
import icon from "../../Images/addIcon.svg";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Btnfilter, DivFilters } from "./ElementCompaniesRequests";
const List = ({
  categories,
  data,
  handleOpenModal,
  filterWithCategory,
  filterWithSubCategory,
}) => {
  const [items, setItems] = useState([]);
  const [categoriesItem, setCategoriesItem] = useState([]);
  const [category, setCategory] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const currentLanguageCode = Cookies.get("i18next");
  const { t } = useTranslation();
  useEffect(() => {
    if (data) {
      if (!category) {
        setCategory(categories?.[0]);
      }
      setCategoriesItem(categories);
      setItems(data);
    }
  }, [categories, data]);
  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const handleSelectTab = (status) => {
    filterWithSubCategory(status);
    setActiveTab(status);
  };
  const handleSelectCategory = (category) => {
    setCategory(category);
    setActiveTab(0);
    filterWithCategory(category?.id);
  };
  return (
    <>
      <Container>
        <ContainerCategories>
          {categoriesItem.map((categoryItem) => (
            <Category
              active={categoryItem?.id === category?.id}
              onClick={() =>
                categoryItem?.id !== category?.id &&
                handleSelectCategory(categoryItem)
              }
            >
              <ImgCategory src={categoryItem?.image} />
              {categoryItem?.title}
            </Category>
          ))}
        </ContainerCategories>
        <ContainerItems>
          <DivFilters>
            <Btnfilter
              onClick={() => handleSelectTab(0)}
              active={activeTab === 0}
            >
              همه
            </Btnfilter>
            {category?.childs?.map((subCategory) => (
              <Btnfilter
                onClick={() => handleSelectTab(subCategory?.id)}
                active={activeTab === subCategory?.id}
              >
                {subCategory?.title}
              </Btnfilter>
            ))}
          </DivFilters>

          {items.map((item) => (
            <Item>
              <Card src={currentLanguageCode === "fa" ? card : card_ltr} />
              <CoverImg status={item?.status === 1 && "active"}>
                <div>
                  <Img loading="lazy" src={item.image} />
                  <div>
                    <div />
                  </div>
                </div>
              </CoverImg>
              <DetailItem>
                <Title>{item.title}</Title>
                <Description>{item.description}</Description>
                <Price isPrice={item?.minimum_price}>
                  {item?.minimum_price
                    ? numberWithCommas(item?.minimum_price)
                    : "بدون قیمت"}
                  <Unit>{item?.minimum_price && t("unit")}</Unit>
                </Price>
              </DetailItem>
              <Btn onClick={() => handleOpenModal("modalOperations", item)}>
                <Icon src={setting} />
              </Btn>
            </Item>
          ))}
          <BtnAdd onClick={() => handleOpenModal("modalAdd")}>
            <IconBtnAdd src={icon} />
            {t("add product")}
          </BtnAdd>
        </ContainerItems>
      </Container>
    </>
  );
};

export default List;
