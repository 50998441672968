import Joi from "joi";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import axiosConfig from "../../../ApiCall/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide } from "react-toastify";

import {
  Body,
  Container,
  Header,
  TitleHead,
  Input,
  DivInput,
  Btn,
  BtnDiv,
  DivIconHead,
  IconHead,
  CoverImg,
  Img,
  Lable,
  DivRow,
  DivColumn,
  DivSelect,
  DivCategories,
  DivCategory,
  ActivCategoryCheckbox,
  ImgCategory,
  DivBoxSubCategories,
  DashedSubCategories,
  ActiveSubCategoryCheckbox,
  DivSubCategories,
  DivSubCategory,
  StatusDiv,
} from "./Element";
import check from "../../../Images/check.svg";
import icon from "../../../Images/headerIcons/products.svg";
import iconSelect from "../../../Images/selectImg/product.svg";
import { useTranslation } from "react-i18next";

const Add = ({
  showAddModal,
  handleAddNew,
  handleCloseModal,
  handleOpenModal,
}) => {
  const [data, setData] = useState({
    title: "",
    image: null,
    description: "",
    measure_unit: "",
    category_id: null,
    status: 1,
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [parent, setParent] = useState(null);
  const [disabledBtn, setDisabledBtn] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setShowModal(showAddModal);
    if (showAddModal) {
      setLoading(true);
      async function fetchCategories() {
        const token = localStorage.getItem("tokenPanelRoysa");
        const params = new URLSearchParams([
          ["per_page", 1000000],
          ["page", 1],
        ]);
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
          params,
        };

        try {
          const { data: categoriesData } = await axiosConfig.get(
            "/category/list",
            config
          );
          setItems(categoriesData);
          setLoading(false);
        } catch (error) {
          console.log("Error fetching Categories:", error);
        }
      }
      fetchCategories();
    }
  }, [showAddModal]);

  const schema = Joi.object({
    image: Joi.string().optional(),
    title: Joi.string().optional(),
    measure_unit: Joi.string().optional(),
    description: [Joi.string().optional(), Joi.allow("")],
    category_id: Joi.number().integer().required(),
    status: [Joi.number().integer().required(), Joi.allow(null)],
  });
  const CloseModal = () => {
    handleCloseModal("modalAdd");
    setData({
      title: "",
      image: null,
      description: "",
      measure_unit: "",
      category_id: null,
      status: 1,
    });
    setParent(null);
    setErrors({});
  };

  const handleChange = (e) => {
    console.log(e.currentTarget.value);
    setData({ ...data, [e.currentTarget.id]: e.currentTarget.value });
  };
  const selectSubCategory = (subCategory) => {
    setData({ ...data, category_id: subCategory.id });
    setParent(parseInt(subCategory.parent_id));
  };
  const handleSubmit = (event) => {
    if (!disabledBtn) {
      event.preventDefault();
      const errors = validate();
      setErrors({ errors: errors || {} });
      if (errors) return;
      setDisabledBtn(true);
      doSubmit();
    }
  };
  const validate = () => {
    const result = schema.validate(data, { abortEarly: false });
    let errorMessage = "";
    if (!result.error) return null;
    if (result.error.details[0].path[0] === "image") {
      errorMessage = "تصویر نمی تواند خالی باشد";
    } else if (result.error.details[0].path[0] === "title") {
      errorMessage = "عنوان نمی تواند خالی باشد";
    } else if (result.error.details[0].path[0] === "measure_unit") {
      errorMessage = "واحد اندازه گیری نمی تواند خالی باشد";
    } else if (result.error.details[0].path[0] === "category_id") {
      errorMessage = "دسته کالایی را انتخاب کنید";
    }
    toast.warning(errorMessage);
    console.log(result.error);
    const errors = {};
    for (const item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };
  const selectFile = () => {
    document.getElementById("fname").click();
  };
  const onSelectImage = (e) => {
    if (e.currentTarget && e.currentTarget.files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64Image = e.target.result;
        setData({ ...data, image: base64Image });
      };
      reader.readAsDataURL(e.currentTarget.files[0]);
      console.log(data);
    }
  };
  const handleChangeStatus = () => {
    let status;
    if (data?.status === 1) {
      status = 0;
    } else {
      status = 1;
    }
    setData({ ...data, status: status });
  };
  const doSubmit = async () => {
    let isOkResponse;
    const toastId = toast.loading(t("creating product ..."));
    const token = localStorage.getItem("tokenPanelRoysa");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      await axiosConfig.post("/product/store", data, config);
      isOkResponse = true;
      setDisabledBtn(false);
    } catch (error) {
      isOkResponse = false;
      console.log("Error response from server:", error.response.data.status);
      toast.update(toastId, {
        render: t("unable to connect to server"),
        type: "error",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
      });
      setDisabledBtn(false);
    }
    if (isOkResponse) {
      handleAddNew(toastId);
      CloseModal();
    }
  };

  return (
    <>
      <ToastContainer
        transition={Slide}
        className="toast-position"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Modal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={CloseModal}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
        closeTimeoutMS={100}
      >
        <Container>
          <Header>
            <DivIconHead>
              <IconHead src={icon} />
            </DivIconHead>
            <TitleHead>{t("create new product")}</TitleHead>
          </Header>
          <Body>
            <DivRow>
              <DivColumn flexEnd>
                <DivInput>
                  <Lable>
                    <br />
                  </Lable>
                  <CoverImg onClick={selectFile}>
                    <Img src={data.image ? data.image : iconSelect} />
                  </CoverImg>
                  <input
                    hidden
                    type="file"
                    id="fname"
                    name="fname"
                    onChange={onSelectImage}
                  ></input>
                </DivInput>
                <DivInput>
                  <Lable>{t("title product")}</Lable>
                  <Input
                    id="title"
                    onChange={handleChange}
                    value={data.title}
                  />
                </DivInput>
                <DivInput>
                  <Lable>{t("measure unit")}</Lable>
                  <Input
                    id="measure_unit"
                    onChange={handleChange}
                    value={data.measure_unit}
                  />
                </DivInput>
                <DivInput>
                  <Lable>{t("description")}</Lable>
                  <Input
                    id="description"
                    onChange={handleChange}
                    value={data.description}
                  />
                </DivInput>
                <StatusDiv
                  onClick={handleChangeStatus}
                  status={data?.status === 1 && "active"}
                >
                  <img src={check} alt="check" />
                  <p>{t("فعال بودن محصول")}</p>
                </StatusDiv>
              </DivColumn>
              <DivColumn flexEnd>
                <DivInput>
                  <Lable>{t("choose of category")}</Lable>
                  <DivSelect>
                    {loading ? (
                      <div className="loadingList">
                        <div className="spinner"></div>
                      </div>
                    ) : (
                      items?.data?.map((item) => (
                        <DivCategories key={item?.id}>
                          <DivCategory>
                            <ActivCategoryCheckbox
                              active={parent === item.id ? true : false}
                            >
                              <ImgCategory src={item?.image} />
                            </ActivCategoryCheckbox>
                            {item?.title}
                          </DivCategory>
                          <DivBoxSubCategories>
                            <DashedSubCategories />
                            <DivSubCategories>
                              {item?.childs?.map((subItem) => (
                                <DivSubCategory key={subItem?.id}>
                                  <ActiveSubCategoryCheckbox
                                    active={
                                      data?.category_id === subItem.id
                                        ? true
                                        : false
                                    }
                                    onClick={() => {
                                      selectSubCategory(subItem);
                                    }}
                                  />
                                  {subItem?.title}
                                </DivSubCategory>
                              ))}
                            </DivSubCategories>
                          </DivBoxSubCategories>
                        </DivCategories>
                      ))
                    )}
                  </DivSelect>
                </DivInput>

                <BtnDiv btnPadding={true}>
                  <Btn okBtn onClick={handleSubmit}>
                    {t("create")}
                  </Btn>
                  <Btn onClick={CloseModal}>{t("cancel")}</Btn>
                </BtnDiv>
              </DivColumn>
            </DivRow>
          </Body>
        </Container>
      </Modal>
    </>
  );
};

export default Add;
